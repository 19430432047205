import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';

import Section from './Section';

import { colors } from '../shared/styles';

const MainTitleContainer = styled.div`
  position : absolute;
  bottom : 10%;
  width : 100%;
  text-align : center;
  display : flex;
  flex-direction : column;
  padding-left : 10rem;
  padding-right : 10rem;
`;

const MainTitleParagraph = styled.h1`
  font-size : 3.2rem;
  text-transform: uppercase;
  margin : 1rem auto;
  line-height : 6rem;
  font-weight : normal;
`;

const MainTitleText = styled.span`
  background-color : ${colors.yellow};
  padding : 0.6rem 0rem;
  letter-spacing : 0.3rem;
  box-shadow: 3rem 0 0 ${colors.yellow}, -3rem 0 0 ${colors.yellow};

  & div {
    display : inline;
  }

  &.hidden {
    visibility : hidden;
  }
`;

export const MainTitle = ({strings = [], isStatic = false}) => {
  const typewriterRef = useRef([]);
  const [currentLine, setCurrentLine] = useState(0);

  return (
    <MainTitleContainer>
      <MainTitleParagraph>
          {
            strings.map(
              (string, index) =>
              <div key={string}>
                <MainTitleText className={currentLine < index ? 'hidden' : ''}>
                  {
                    isStatic ? 
                    string : 
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriterRef.current[index] = typewriter;

                        typewriter.typeString(string)
                          .callFunction(() => {
                            const tw = typewriterRef.current[index+1];
                            if(tw) {
                              tw.start();
                              setCurrentLine(currentLine+1);
                            }
                          });

                        if( index === 0 ) {
                          typewriter.start();
                        }
                      }}
                      options={{
                        cursor : "",
                        delay : 50
                      }}
                    />
                    }
                </MainTitleText>
              </div>
            )
          }
      </MainTitleParagraph>
    </MainTitleContainer>
  );
}




const SectionTitleContainer = styled.h2`
  width : 100%;
  background-color: black;
  font-family : TellMeMore;
  text-transform : uppercase;
  color : white;
  text-align : center;
  letter-spacing : 0.5rem;
  font-weight : normal;
  padding : 1.2rem 1rem 0.7rem 1rem;
  margin-top : 9rem;
  margin-bottom : 9rem;
  font-size : 3.8rem;
`


export const SectionTitle = ({children}) =>
  <Section>
    <SectionTitleContainer>
      {children}
    </SectionTitleContainer>
  </Section>
