/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"

import styled from "styled-components"

import Header from "./header"
import Footer from './Footer';
import Menu from './Menu';

import "./layout.css"

const Main = styled.main`
  position : relative;
`

const Spacer = styled.div`
  transition : height 0.8s ease;
`;

const Layout = ({ children, className, offsetTop}) => {
  const [menuVisible, setMenuVisible ] = useState(false);

  return (
    <React.Fragment>
      {
        offsetTop !== undefined?
          <Spacer style={{height : `${offsetTop || 0}px`, width:'100%'}}/> :
          null
      }
      <Main className={className}>
        <Header onBugerClick={() => setMenuVisible(true)}/>
        <Menu visible={menuVisible} onCloseClick={() => setMenuVisible(false)}/>
        {children}
        <Footer />
      </Main>
    </React.Fragment>
  );
}

export default Layout
