import React from "react"
import styled from "styled-components"


const MainImageSection = styled.div`
  width : 100%;
  height : 100%;
  background-image : url('${({image}) => image}');
  background-size : cover;
  background-position : center center;
  background-repeat : no-repeat;

  @media screen and (max-width: 425px) {
    background-image : url('${({image, mobileImage}) => mobileImage ? mobileImage : image}');
  }
`;

const DesaturatedMainImageSectionContainer = styled.div`
  width : 100%;
  height : 100%;
`;

const DesaturatedMainImageSectionImage = styled.img`
  z-index : 0;
  position : absolute;
  top : 0;
  left : 0;
  height : 100%;
  width : 100%;
  object-fit : cover;
  filter: brightness(1.2) grayscale(1) contrast(0.8);
`;


export const DesaturatedMainImageSection = ({children, image, ...props}) => 
  <DesaturatedMainImageSectionContainer {...props}>
    <DesaturatedMainImageSectionImage src={image}/>
    {children}
  </DesaturatedMainImageSectionContainer>;

export default MainImageSection;
