import React, { useState } from "react"
import styled from "styled-components";
import { Link } from "gatsby";
import Typewriter from 'typewriter-effect';

import { colors } from '../shared/styles';


const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  background-color : ${colors.yellow};
  z-index : 1;
  background-position : left center;
  background-size : contain;
  background-repeat : no-repeat;
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
  transition : transform 0.3s ease;
  transform : translateX(100%);

  &.visible {
    transform : translateX(0);
  }
`;

const MenuLink = styled(Link)`
  color : black;
  text-decoration : none;
  text-transform: uppercase;
  font-size : 3rem;
  margin-top : 2rem;
  margin-bottom : 2rem;
`;

const Close = styled.img`
  width : 4rem;
  height : 4rem;
`;

const CloseWrapper = styled.div`
  position : absolute;
  top : 0;
  right : 0;
  padding : 3rem;

  @media screen and (min-width: 426px) {
    display : none;
  }
`


const Menu = ({ visible, onCloseClick }) => {

  return (
    <Container className={visible ? 'visible' : ''}>
      <CloseWrapper>
        <Close onClick={onCloseClick} src={require('../images/close_menu.png')}/>
      </CloseWrapper>
      <MenuLink to="/#home">Home</MenuLink>
      <MenuLink to="/presentation">Qui sommes-nous ?</MenuLink>
      <MenuLink to="/offre">Notre offre</MenuLink>
      <MenuLink to="/clients">Réferences</MenuLink>
      <MenuLink to="/blog">Blog</MenuLink>
      <MenuLink to="/contact">Contactez-nous</MenuLink>
    </Container>
  )
};

export default Menu;
