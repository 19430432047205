import React, {useState} from 'react';
import styled from 'styled-components';
import { Link } from "gatsby";

import Section, {ColumnsSection, SectionColumn} from './Section';

import { colors } from '../shared/styles';

const NavigationSection = styled(ColumnsSection)`
  border-bottom : 1px solid white;
  display : flex;
  flex-direction : row;
  justify-content : space-around;
  padding : 2rem 0;

  @media screen and (max-width: 425px) {
    align-items : center;
    margin-top : 12rem;
    padding-bottom : 10rem;
  }

`;

const FooterLink = styled(Link)`
  text-transform : uppercase;
  color : white;
  text-decoration : none;
  font-size : 1.6rem;
  letter-spacing : 0.2rem;

  @media screen and (max-width: 425px) {
    margin-top : 1rem;
    margin-bottom : 1rem;
  }
`;

const ContactSection = styled(ColumnsSection)`
  color : white;
  font-size : 1.3rem;
  align-items : center;
`;

const Column = styled(SectionColumn)`
  padding : 10rem 0;
`;

const LeftColumn = styled(Column)`
  text-align : left;

  @media screen and (max-width: 425px) {
    text-align : center;
  }
`;

const RightColumn = styled(Column)`
  text-align : right;

  @media screen and (max-width: 425px) {
    text-align : center;
  }

`;

const Paragraph = styled.div`
  font-size : 2rem;
  line-height : 3rem;
`;

const Contacter = styled(Link)`
  font-weight : bolder;
  font-style : italic;
  color : white;
  text-decoration : none;
  &:hover {
    text-decoration : underline;    
  }
`;

const SocialLink = styled.a`
  text-decoration : none;
  margin-left : 1.1rem;
`;

const SocialIcon = styled.img`
  width : 3.5rem;
  height : 3.5rem;
  margin-top : 2rem;
`;

const Logo = styled.img`
  width : 15rem;
`;

const CopyrightSection = styled.div`
  background-color : ${colors.darkGrey};
  padding : 3rem;
  color : white;
  text-transform : uppercase;
  text-align : center;
  font-size : 1.1rem;
  letter-spacing : 0.5rem;
`;

const LegalLink = styled(Link)`
  color : white;
  text-decoration : none;
`;

const Footer = () =>
  <React.Fragment>
    <NavigationSection wrapperStyle={{backgroundColor : 'black'}}>
      <FooterLink to="/#home">Home</FooterLink>
      <FooterLink to="/presentation">Qui sommes-nous ?</FooterLink>
      <FooterLink to="/offre">Notre offre</FooterLink>
      <FooterLink to="/clients">Ils nous font confiance</FooterLink>
      <FooterLink to="/contact">Contactez-nous</FooterLink>
    </NavigationSection>
    <ContactSection wrapperStyle={{backgroundColor : 'black'}}>
      <LeftColumn>
        <Paragraph>
          Vous souhaitez échanger
        </Paragraph>
        <Paragraph>
          sur vos problématiques,
        </Paragraph>
        <Paragraph>
          connaître nos tarifs ?
        </Paragraph>
        <Paragraph>
          N’hésitez pas à nous <Contacter to="/contact">contacter</Contacter> !
        </Paragraph>
      </LeftColumn>
      <Logo src={require('../images/acronyme_alpha.png')}/>
      <RightColumn>
        <Paragraph>
          contact@tmm-agency.fr
        </Paragraph>
        <Paragraph>
          <SocialLink href="https://linkedin.com">
            <SocialIcon src={require('../images/linkedin.png')} />
          </SocialLink>
          <SocialLink href="https://linkedin.com">
            <SocialIcon src={require('../images/facebook.png')} />
          </SocialLink>
          <SocialLink href="https://linkedin.com">
            <SocialIcon src={require('../images/insta.png')} />
          </SocialLink>
        </Paragraph>
      </RightColumn>
    </ContactSection>
    <CopyrightSection>
      © TELL ME MORE - 2020 - <LegalLink to="/mentions-legales">Mentions légales</LegalLink>
    </CopyrightSection>
  </React.Fragment>

export default Footer;
